<template>
    <div class="resume">
        <div class="title">
            <div class="sub-title">消息列表</div>
            <!-- <div class="sub-filtter"><i class="el-icon-delete"></i> 清空索索条件</div> -->
        </div>
        <el-card class="box-card" style="min-height:100%">
            <div class="listbox">
                <ul v-loading="loading">
                    <li v-for="(item,index) in list">
                        <div class="messagetop" @click="gotoShow(item)"><span>{{item.memberName}}</span> 与 <em>{{item.userName}}</em>   <i>时间：{{item.createTime}}<u v-if="item.sign==1"></u></i></div>
                    </li>
                </ul>
                <div class="page">
                    <el-pagination
                    background
                    layout="prev, pager, next"
                    :page-size="size"
                    @current-change="changepage"
                    @prev-click="prevpage"
                    @next-click="nextpage"
                    :current-page="page"
                    :total="total">
                    </el-pagination>
                </div>
            </div>
        </el-card>
    </div>
</template>
<script>
import companyRequest from '../../../api/company'
export default {
    data(){
        return{
            list:[],
            total:0,
            page:1,
            size:15,
            user:null,
            loading:false
        }
    },
    created(){
        this.user=JSON.parse(localStorage.getItem("userInfo"))
        this.getList()
    },
    methods:{
        gotoShow(row){
            this.$router.push({path:"/company/messageshow",query:{u:row.userId,m:row.memberId}})
        },
        getList(){
            this.loading=true
            companyRequest.listByPid({pageNo:this.page,pageSize:this.size,id:this.user.id,isMember:true}).then(res=>{
                console.log(res)
                this.loading=false
                this.list=res.data.list
                this.total=res.data.total
            })
        },
        changepage(e){
            this.page=e
            this.getList()
        },
        prevpage(){
            this.page=this.page-1
            if(this.page<=0){
                this.page=1
            }
            this.getList()
        },
        nextpage(){
            this.page=this.page+1
            let maxnum=Mail.ceil(this.total/this.size)
            if(this.page>=maxnum){
                this.page=maxnum
            }
            this.getList()
        }
    }
}
</script>
<style scoped>
.listbox{  box-sizing: border-box; background: #ffffff; padding:10px;}
.listbox ul li{ display: block; padding:0 10px;}
.listbox ul li:nth-child(even){ background: #fafafa;}
.messagetop{ height: 45px; line-height: 45px; cursor: pointer; position: relative;}
.messagetop span{color:#409eff; font-weight: bold;}
.messagetop em{ font-style: normal; color:#f90; font-weight: bold;}
.messagetop i{ font-style: normal; color:#999; float: right; }
.page{ text-align: center; padding:30px 0;}
.page /deep/ .el-pager li.active{color:#ffffff!important;}
.messagetop u{ display: block; position: absolute; width: 8px; height: 8px; border-radius: 4px; background: #f60; top:5px; right:-6px;}
</style>